import React, { useContext } from "react";

import CraftClaim from "../../components/CraftClaim/CraftClaim";
import { Web3Context } from "../../data/Web3Context/Web3Context";

import "./Main.css";

function Main() {
  const { account, connectMetamask } = useContext(Web3Context);
  
  return (
    <div className="CraftPage CraftPage_Full">
        <p className="CraftPage_Headline">
        Mint your <span>BYOCraft</span>
      </p>
      {!account ? (
        <button
          onClick={() => connectMetamask()}
          className="Main_ConnectButton"
        >
          {`CONNECT WALLET TO PROCEED`}
        </button>
      ) : (
        <CraftClaim />
      )}

      <p className="CraftPage_AddressFooter" onClick={() => {connectMetamask()}}>
        {account ? account : "Connect your Wallet"}
      </p>
    </div>
  );
}

export default Main;
