import React, { useState, useEffect, useContext } from "react";
import { Web3Context } from "../../data/Web3Context/Web3Context";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./CraftClaim.css";
import { handleError } from "../../helper/errorHandler";
import TransactionModal from "../TransactionModal/TransactionModal";
import UsedCheck from "../UsedCheck/UsedCheck";

function CraftClaim() {
  const { web3, account, pillContract, craftContract, vapeContract } =
    useContext(Web3Context);
  
  const [pillInput, setPillInput] = useState ("");
  const [selected, setSelected] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);

  const [claimCount, setClaimCount] = useState(0);
  const [vapeCount, setVapeCount] = useState(0);
  const [eligibleCount, setEligibleCount] = useState(0);
  const [usedCheckOpen, setUsedCheckOpen] = useState(false);

  // Minting properties
  const [isMintLoading, setMintLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [transactionHash, setTransactionHash] = useState(null);
  const [transactionConfirmed, setTransactionConfirmed] = useState(false);

  useEffect(() => {
    if (!pillContract || !craftContract || !vapeContract) {
      return;
    }

    fetchClaims();
    fetchEligibility();
    fetchVapeBalance();

    var claimInterval = setInterval(() => {
      fetchClaims();
    }, 5000);
    var vapeBalanceInterval = setInterval(() => {
      fetchVapeBalance();
    }, 5000);

    return () => {
      clearInterval(claimInterval);
      clearInterval(vapeBalanceInterval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, pillContract, craftContract, vapeContract]);


  const parseInput = (input) => {
    const items = input.split("/");
    var data = [];

    items.forEach (item => {
      if (item !== "") {
        try {
          var arr = JSON.parse(item);
          data.push (arr);
        }
        catch (e) {}
      }
    })

    setSelected(data);
    setSelectedCount(data.length);
    setPillInput (input);
  };

  const parseSelected = () => {
    const currSelected = selected;
    const parsedPills = [];
    const parsedApostles = [];

    currSelected.forEach(s => {
      parsedPills.push (s[0]);
      parsedApostles.push (s[1]);
    });

    return { parsedPills, parsedApostles }
  }

  const fetchEligibility = async () => {
    try {
    const fetchRes = await fetch(
      `https://api.byopills.com/byocraft/snapshot/${account}`
    );
    if (!fetchRes.ok) {
      throw new Error('Something went wrong');
    }
    const data = await fetchRes.json();
    setEligibleCount(data.count);
  }
  catch (e) {
    alert ("An error has occured fetching eligibility data, please refresh and try again.");
    return;
  }
  };

  const fetchClaims = async () => {
    const { m_craftClaims } = craftContract.methods;
    const claims = await m_craftClaims(account).call();
    setClaimCount(claims);
  };

  const fetchVapeBalance = async () => {
    const { balanceOf } = vapeContract.methods;
    const balance = await balanceOf(account, 0).call();
    setVapeCount(balance);
  };

  const mintCrafts = async () => {
    if (isMintLoading) {
      return;
    }

    const selecteds = parseSelected();

    setMintLoading(true);

    const fetchRes = await fetch(
      `https://api.byopills.com/byocraft/merkle/proof/${account}`
    );
    const data = await fetchRes.json();

    if (!data) {
      alert("An unknown error has occured. Please try again in a few.");
      setMintLoading(false);
      return;
    }

    const { idx, maxCount, proof } = data;

    if (proof === "Not Found" || isNaN(maxCount)) {
      alert("This address is not on the whitelist.");
      setMintLoading(false);
      return;
    }

    // Reset
    setTransactionHash(null);
    setTransactionConfirmed(false);

    const { mintCrafts } = craftContract.methods;

    mintCrafts(selecteds.parsedPills, selecteds.parsedApostles, idx, maxCount, proof).estimateGas(
      {
        from: account
      },
      async (err, gas) => {
        if (err) {
          handleError(err);
          setMintLoading(false);
          return;
        }
        mintCrafts(selecteds.parsedPills, selecteds.parsedApostles, idx, maxCount, proof).send(
          {
            from: account,
            gas: gas,
          },
          async function (error, txHash) {
            if (error) {
              setMintLoading(false);
              return;
            }
            
            setTransactionHash(txHash);
            setModalOpen(true);
            setMintLoading(false);

            let transactionReceipt = null;
            while (transactionReceipt == null) {
              transactionReceipt = await web3.eth.getTransactionReceipt(txHash);
              await sleep(1000);
            }

            setTransactionConfirmed(true);
            setTransactionHash(transactionReceipt.transactionHash);

          }
        );
      }
    );
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  return (
    <div className="PillGrid_Container">

      <UsedCheck isOpen={usedCheckOpen} onClose={() => setUsedCheckOpen(false)} />

      <TransactionModal open={modalOpen} transactionHash={transactionHash} transactionConfirmed={transactionConfirmed} account={account} onClose={() => { setModalOpen(false) }} />

      <p className="CraftClaim_Title">ALTERNATE CRAFT MINT</p>

      <p className="CraftClaim_MoreInfo">
        Craft minting is only available for eligible addresses. This is an alternate website that allows you to mint manually in case you are having trouble with the other.
      </p>

      <p className="CraftClaim_Description">INPUT THE IDs OF THE PILLS, APOSTES BELOW, USING THE FORMAT SHOWN BELOW [PILLID, APOSTLEID], -1 IS NO APOSTLE</p>

      <textarea className="CraftClaim_TextArea" value={pillInput} onChange={(e) => { parseInput(e.target.value) }} placeholder="Example: [55,67]/[76,-1]/[8994,-1]" />

      {isMintLoading ? (
        <button className="CraftPage_MintButton">LOADING ...</button>
      ) : selectedCount > 0 ? (
        <button onClick={() => mintCrafts()} className="CraftPage_MintButton CraftPage_MintButtonActive">
          {`MINT ${selectedCount} CRAFT(s)`} <br />
          <span className="CraftClaim_MaxNotice">Max 50 Per Tx.</span>
        </button>
      ) : (
        <button className="CraftPage_MintButton CraftPage_MintButtonDisabled">
          {`INPUT PAIRS FOR CRAFT(s)`}
        </button>
      )}

      <p onClick={() => setUsedCheckOpen(true)} className="CraftClaim_UsedCheckButton">CHECK USED PILLS / APOSTLES</p>

      <div className="CraftClaim_Info">
        <p style={{ color: "#9d00ff" }}>
          Eligible For: <span>{eligibleCount} Craft(s)</span>
        </p>
        <p style={{ color: "#9d00ff", flex: "0.5" }}>
          Vape Balance: <span>{vapeCount} Vape(s)</span>
        </p>
        <p style={{ color: "#9d00ff", flex: "0.5" }}>
          Crafts Claimed: <span>{claimCount} Craft(s)</span>
        </p>
      </div>
    </div>
  );
}

export default CraftClaim;
