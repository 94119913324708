import React from 'react';
import { SkyLightStateless } from 'react-skylight';
import Loader from 'react-loader-spinner';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './TransactionModal.css';

function TransactionModal({ open, onClose, account, transactionConfirmed, transactionHash}) {
    return (
        <SkyLightStateless
        isVisible={open}
        onCloseClicked={() => {
            onClose();
        }}
      >
        <div className="Modal_Body">
          {!transactionConfirmed ? (
            <div className="Modal_Title">
              <Loader type="Puff" color="white" height={50} width={50} />
              <p className="Modal_Status">
                Transaction Processing ... <br />
                <span>
                  You can either wait or close this and check your wallet or
                  etherscan for confirmation
                </span>
              </p>
            </div>
          ) : (
            <div className="Modal_Title">
              <p>Transaction Confirmed !</p>
            </div>
          )}

          <p>SUMMARY</p>
          <div className="separator"></div>
          <div className="Modal_Entries">
            <div className="Modal_Entry">
              <p>TRANSACTION HASH</p>
              <p>
                {transactionHash}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://etherscan.io/tx/${transactionHash}`}
                >
                  MORE INFO...
                </a>
              </p>
            </div>
            <div className="Modal_Entry">
              <p>FROM</p>
              <p>{account}</p>
            </div>
          </div>
          <div className="separator"></div>

          <div className="Modal_Actions">
            <button
              onClick={() => {
               onClose();
              }}
            >
              Mint more
            </button>
          </div>
          {transactionConfirmed && (
            <div className="Modal_Confirmation">
              Your transaction has now been confirmed ! Your BYOCraft should now
              be minted, and visible on{' '}
              <a href="https://opensea.io/collection/byocraft">Opensea.io 🙌 </a>
            </div>
          )}
        </div>
      </SkyLightStateless>
    )
}

export default TransactionModal
